import './Home.css'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getFirestore, getDocs, doc, updateDoc } from "firebase/firestore"
import { initializeApp } from "firebase/app";
import { GoogleGenerativeAI } from "@google/generative-ai";
import Cookies from "js-cookie";
import { SearchTopic } from './userdata';
import { History } from './userdata';

export default function HomeEn() {
    const [inputValue, setInputValue] = useState("");
    const navigate = useNavigate()
    const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_KEY);
    const model = genAI.getGenerativeModel({ model: "gemini-1.0-pro" });
    const backgrounds = [["Marine Biology", "marine-biology.png"], ["Chemical Reactions", "chemical-reactions.jpeg"], ["Climate Change", "climate-change.webp"], ["Cold War", "cold-war.jpeg"], ["Geometry", "geometry.jpeg"], ["Laws of Motion", "newton-laws.jpeg"], ["Solar System", "solar-system.jpeg"], ["Stoichiometry", "stoichiometry.jpeg"],]
    const [currentBackground,] = useState(backgrounds[Math.floor(Math.random() * backgrounds.length)])
    const [isLoading, setIsLoading] = useState(false)
    const [historyBarOpened, setHistoryBarOpened] = useState(false)
    const [historyData, setHistoryData] = useState(new History())
    const [historyPerDate, setHistoryPerDate] = useState([[], [], []])

    useEffect(() => {
        const data = Cookies.get(`history`)
        if (data) {
            try {
                const parsedData = JSON.parse(data)
                setHistoryData(parsedData)
                setHistoryPerDate([
                    parsedData.topicsViewed.filter(t => {
                        const now = Date.now();
                        const oneWeek = 7 * 24 * 60 * 60 * 1000;
                        const diff = now - t.date;
                        return diff < oneWeek
                    }),
                    parsedData.topicsViewed.filter(t => {
                        const now = Date.now();
                        const oneWeek = 7 * 24 * 60 * 60 * 1000;
                        const oneMonth = 30 * 24 * 60 * 60 * 1000;
                        const diff = now - t.date;
                        return diff < oneMonth && diff > oneWeek
                    }),
                    parsedData.topicsViewed.filter(t => {
                        const now = Date.now();
                        const oneMonth = 30 * 24 * 60 * 60 * 1000;
                        const diff = now - t.date;
                        return diff > oneMonth
                    }),
                ])
            } catch {
                console.log("couldn't get history data.")
            }
        } else {
            setHistoryData(new History())
            setHistoryPerDate([[], [], []])
        }
    }, [])

    const isItATopic = async () => {
        const result = await model.generateContent(`Responda unicamente com sim ou não: você consegue gerar um resumo para estudantes sobre "${inputValue}"? Se for uma palavra ou algo sem sentido, por favor, responda que não, mas preciso que sua resposta seja APENAS A PALAVRA sim, ou A PALAVRA não.`)
        const response = await result.response;
        const text = await response.text().toLowerCase();
        if (text === "não" || text === "nao") {
            return false
        } else {
            return true
        }
    }

    const getResponseForGivenPrompt = async (inputValue) => {
        try {
            var text = ""
            while (text === "") {
                const result = await model.generateContent(
                    `
                    Generate a detailed educational topic about ${inputValue}.

                    The topic should include the following components:

                    Language: If the topic is clearly in Portuguese (ex: Carnaval="en", Samba="en", Segunda Guerra Mundial="pt-BR"), use pt-BR. Otherwise, use english and generate all answers below in english.
                    Name: The name of the topic should be clear, precise, and directly related to the subject, as close as possible to ${inputValue}, without any explanations.
                    Resumo: Write a summary of the topic with approximately 30 lines. The summary should provide a comprehensive overview of the main concepts, including historical context and relevance. Use <bold> (and not **word**) to highlight key terms and <p> to separate paragraphs. Do not include titles, lists, or other formatting tags. The text should not contain markdown and must be strictly formatted in HTML. Remember to highlight the key terms in the summary, not just the topic itself, but the actual key terms!                    
                    Cards: Create five cards, each with a key term related to the topic and its detailed definition. The cards should be simple strings, without HTML.                      
                    Quiz: Create a quiz with five questions about the topic. Each question should include:
                    One correct answer (specified by the "answer" property, which should be 1, 2, 3, or 4).
                    Four answer options (opt1, opt2, opt3, opt4).
                    For each incorrect answer, provide an explanation (opt1exp, opt2exp, opt3exp, opt4exp) respectively. If the answer is correct, the explanation should be an empty string. Do that for EVERY WRONG ANSWER

                    Output format: The output should be a PURE JSON STRING and follow exactly the structure below. Do not include code blocks, extra formatting, or additional characters. The response should be formatted so that it can be directly used within a string surrounded by backticks in JavaScript.

                    { "id": "string", "language": "pt-BR", "name": "string", "resumo": "string", "cards": [ { "palavra": "string", "definicao": "string" }, { "palavra": "string", "definicao": "string" }, { "palavra": "string", "definicao": "string" }, { "palavra": "string", "definicao": "string" }, { "palavra": "string", "definicao": "string" } ], "quiz": { "questions": [ { "question": "string", "opt1": "string", "opt1exp": "string (se opt1 tiver errado, a explicação do por que. se não, string vazia)", "opt2": "string", "opt2exp": "string (se opt2 tiver errado, a explicação do por que. se não, string vazia)", "opt3": "string", "opt3exp": "string (se opt3 tiver errado, a explicação do por que. se não, string vazia)", "opt4": "string", "opt4exp": "string (se opt4 tiver errado, a explicação do por que. se não, string vazia)", "answer": "int (a opção certa. 1, 2, 3 ou 4)" }, { "question": "string", "opt1": "string", "opt1exp": "string", "opt2": "string", "opt2exp": "string", "opt3": "string", "opt3exp": "string", "opt4": "string", "opt4exp": "string", "answer": "int" }, { "question": "string", "opt1": "string", "opt1exp": "string", "opt2": "string", "opt2exp": "string", "opt3": "string", "opt3exp": "string", "opt4": "string", "opt4exp": "string", "answer": "int" }, { "question": "string", "opt1": "string", "opt1exp": "string", "opt2": "string", "opt2exp": "string", "opt3": "string", "opt3exp": "string", "opt4": "string", "opt4exp": "string", "answer": "int" }, { "question": "string", "opt1": "string", "opt1exp": "string", "opt2": "string", "opt2exp": "string", "opt3": "string", "opt3exp": "string", "opt4": "string", "opt4exp": "string", "answer": "int" } ] } }

                    Attention: Do not include any code blocks, such as backticks, JSON backticks, or any other extra formatting. The JSON should be generated in a format that can be directly inserted into a string surrounded by backticks in JavaScript, without alterations.
                    Be careful not to fall into the following errors: (JSON Parse error: Property name must be a string literal), (JSON Parse error: Unexpected EOF), (JSON Parse error: Expected '}'), (JSON Parse error: Property name must be a string literal).
                    Problems I am encountering (be careful not to fall into these): Summary is coming with too many paragraphs and becoming useless, words are being highlighted like this: example, summary is coming very small (it should be 30 lines) // DO NOT DO ANY OF THESE THINGS
                    And the most important thing, dont do '''json!!! STRAIGHT FORWARD PURE JSON STRING READY TO BE PARSED!!! NO '''JSON!!!
                    `
                );
                const response = await result.response;
                text = await response.text();
            }
            return text
        }
        catch (error) {
            console.log("Something Went Wrong");
        }
    }

    const idMaker = (string) => {
        return string
            .replace(/\s+/g, '-')
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, "");
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value)
    }

    const revisai = async () => {
        setInputValue(inputValue.trim())
        if (inputValue.length > 1) {
            setIsLoading(true)
            const data = await getTopics()
            const id = idMaker(inputValue)
            if (data[id] === undefined) {
                const isIt = await isItATopic()
                if (isIt === true) {
                    const docRef = doc(db, "revisai", "topicos")
                    const prompt = await getResponseForGivenPrompt(inputValue)

                    try {
                        JSON.parse(prompt)
                        await updateDoc(docRef, { [id]: prompt })
                        const copyHistoryData = historyData
                        const previousAccessId = copyHistoryData.topicsViewed.findIndex(h => h.id === id)
                        if (previousAccessId !== -1) {
                            copyHistoryData.topicsViewed[previousAccessId].date = Date.now()
                        } else {
                            const newTopic = new SearchTopic()
                            newTopic.name = inputValue
                            newTopic.id = id
                            newTopic.date = Date.now()
                            copyHistoryData.topicsViewed.push(newTopic)
                        }
                        setHistoryData(copyHistoryData)
                        saveCookies()
                        await navigate(`/en/${idMaker(inputValue)}`)
                    } catch (error) {
                        console.log(error)
                        await navigate(`/en/404`)
                    }

                } else {
                    await navigate(`/en/404`)
                }
            } else {
                const copyHistoryData = historyData
                const previousAccessId = copyHistoryData.topicsViewed.findIndex(h => h.id === id)
                if (previousAccessId !== -1) {
                    copyHistoryData.topicsViewed[previousAccessId].date = Date.now()
                } else {
                    const newTopic = new SearchTopic()
                    newTopic.name = inputValue
                    newTopic.id = id
                    newTopic.date = Date.now()
                    copyHistoryData.topicsViewed.push(newTopic)
                }
                setHistoryData(copyHistoryData)
                saveCookies()
                await navigate(`/en/${id}`)
            }
        }
    }

    const revisaiParameter = async (p) => {
        if (p.length > 1) {
            setIsLoading(true)
            const data = await getTopics()
            const id = idMaker(p)
            if (data[id] === undefined) {
                const docRef = doc(db, "revisai", "topicos")
                const prompt = await getResponseForGivenPrompt(p)

                try {
                    JSON.parse(prompt)
                    await updateDoc(docRef, { [id]: prompt })
                    const copyHistoryData = historyData
                    const previousAccessId = copyHistoryData.topicsViewed.findIndex(h => h.id === id)
                    if (previousAccessId !== -1) {
                        copyHistoryData.topicsViewed[previousAccessId].date = Date.now()
                    } else {
                        const newTopic = new SearchTopic()
                        newTopic.name = p
                        newTopic.id = id
                        newTopic.date = Date.now()
                        copyHistoryData.topicsViewed.push(newTopic)
                    }
                    setHistoryData(copyHistoryData)
                    saveCookies()
                    await navigate(`/en/${id}`)
                } catch (error) {
                    console.log(error)
                    await navigate(`/en/404`)
                }
            } else {
                const copyHistoryData = historyData
                const previousAccessId = copyHistoryData.topicsViewed.findIndex(h => h.id === id)
                if (previousAccessId !== -1) {
                    copyHistoryData.topicsViewed[previousAccessId].date = Date.now()
                } else {
                    const newTopic = new SearchTopic()
                    newTopic.name = p
                    newTopic.id = id
                    newTopic.date = Date.now()
                    copyHistoryData.topicsViewed.push(newTopic)
                }
                setHistoryData(copyHistoryData)
                saveCookies()
                await navigate(`/en/${id}`)
            }
        }
    }

    function saveCookies() {
        Cookies.set("history", JSON.stringify(historyData));
    }


    const firebaseApp = initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_KEY,
        authDomain: "revisai-a1749.firebaseapp.com",
        projectId: "revisai-a1749",
    });

    const db = getFirestore(firebaseApp);
    const userCollectionRef = collection(db, "revisai")

    const getTopics = async () => {
        const data = await getDocs(userCollectionRef)
        return data.docs[0].data()
    }

    const handleKeyDown = (evt) => {
        if (evt.key === "Enter") {
            revisai()
        }
    }

    return (
        <div className="revisai-home" onKeyDown={handleKeyDown}>
            <div className='revisai-background' onClick={() => setHistoryBarOpened(false)}><img src={`./${currentBackground[1]}`} alt='background'></img></div>

            <div
                className={`historybar ${historyBarOpened ? 'open' : ''}`}
                onMouseLeave={() => setHistoryBarOpened(false)}
            >
                <div className='historybar-titulo'>History</div>
                <div className='historybar-separador'></div>

                {
                    historyPerDate[0].length === 0 && historyPerDate[1].length === 0 && historyPerDate[2].length === 0 ?
                    <div className='historybar-filler'>
                        You haven't searched yet
                    </div> :
                    <></>
                }

                {historyPerDate[0].length > 0 ?
                    <div className='historybar-section'>
                        <div className='historybar-section-title'>This week</div>
                        {historyPerDate[0].map((item, index) => (
                            <a className='historybar-link' key={item.id} href={`/en/${item.id}`}>{item.name}</a>
                        ))}
                    </div>
                    : null}

                {historyPerDate[1].length > 0 ?
                    <div className='historybar-section'>
                        <div className='historybar-section-title'>This month</div>
                        {historyPerDate[1].map((item, index) => (
                            <a className='historybar-link' key={item.id} href={`/en/${item.id}`}>{item.name}</a>
                        ))}
                    </div>
                    : null}

                {historyPerDate[2].length > 0 ?
                    <div className='historybar-section'>
                        <div className='historybar-section-title'>Old days</div>
                        {historyPerDate[2].map((item, index) => (
                            <a className='historybar-link' key={item.id} href={`/en/${item.id}`}>{item.name}</a>
                        ))}
                    </div>
                    : null}

                <div style={{ margin: "30px" }}></div>
            </div>

            <div className='revisai-content'>
                <nav>
                    <div className="icon">
                        <a href="#"><img src="./logobranco.png" alt="logo" /></a>
                        <div className="icon-title">Beta (Early Access)</div>
                    </div>
                    <button className='menu-button' onClick={() => setHistoryBarOpened(true)}><img src='./menu.png' alt='menu' /></button>
                </nav>


                <div className="center-block">
                    <div className="text">
                        <div className="title">Review and learn everything about <span className='home-topic' onClick={() => revisaiParameter(currentBackground[0])}>{currentBackground[0]}</span></div>
                        <div className="subtitle">The platform to help you review your subjects intelligently!</div>
                    </div>
                </div>

                <input type="text" maxLength={25} placeholder="World War II" value={inputValue} onChange={handleInputChange} />

                <button className='submit' onClick={revisai} disabled={inputValue.length <= 1 || isLoading}>
                    <img src={isLoading ? "./spinner.svg" : "./textobotao.png"} alt="botão escrito revisai!" />
                </button>
            </div>
        </div>
    )
}