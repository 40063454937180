import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom"; // Importe 'Routes' também
import HomePt from "./home-pt";
import HomeEn from "./home-en";
import TopicView from "./topicview";
import EnTopicView from "./topicviewen";
import Erro from "./404";
import ErroEn from "./404en";
import { Analytics } from "@vercel/analytics/react"

const RoutesComponent = () => { // Renomeado para evitar conflitos com o import 'Routes'
    return (
        <BrowserRouter>
            <Routes> {/* 'Routes' usado corretamente aqui para envolver os componentes 'Route' */}
                <Route path="/" element={<HomePt />}/>
                <Route path="/en" element={<HomeEn />}/>
                <Route path="/404" element={<Erro />} />
                <Route path="/en/404" element={<ErroEn />} />
                <Route path="/en/:id" element={<EnTopicView />} />
                <Route path="/:id" element={<TopicView />} />
            </Routes>
            <Analytics />
        </BrowserRouter>
    );
};

export default RoutesComponent;
