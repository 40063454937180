import "./404.css"
export default function ErroEn() {
    return (
        <div className="error-page">
            <img src="../wrong.png" alt="404 not found" />
            <div className="error-title">404 not found :/</div>
            <div className="error-text">Sorry, something went wrong with our little robots. Check if the topic name you used makes sense and try again!</div>
            <a className="error-voltar" href="/en">back to home</a>
        </div>
    )
}