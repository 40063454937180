import "./Topic.css"
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react"
import { collection, getFirestore, getDocs } from "firebase/firestore"
import { initializeApp } from "firebase/app";
import { Topic } from "./topic";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { TopicData } from "./userdata.js"
import { format } from "date-fns";

export default function TopicView() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [topic, setTopic] = useState(new Topic())
    const [currentCard, setCurrentCard] = useState(0)
    const [currentCardStatus, setCurrentCardStatus] = useState(false)
    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [quizState, setQuizState] = useState("question") // question, right, wrong, end
    const [quizPoints, setQuizPoints] = useState(0)
    const [quizExplanation, setQuizExplanation] = useState("")
    const [quizReview, setQuizReview] = useState(["Tudo bem, foi só um tropeço!", "Vamos dar uma revisada e tentar de novo?"])
    const [saveData, setSaveData] = useState("")
    const [cardDataHover, setCardDataHover] = useState(false)
    const [historyBarOpened, setHistoryBarOpened] = useState(false)
    const [historyPerDate, setHistoryPerDate] = useState([[], [], []])

    const firebaseApp = initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_KEY,
        authDomain: "revisai-a1749.firebaseapp.com",
        projectId: "revisai-a1749",
    });

    const db = getFirestore(firebaseApp);
    const userCollectionRef = collection(db, "revisai")

    useEffect(() => {
        const getTopics = async () => {
            const data = await getDocs(userCollectionRef)
            try {
                setTopic(JSON.parse(data.docs[0].data()[id]))
            } catch {
                await navigate(`/404`)
            }
        }
        getTopics()

        const cookie = Cookies.get(`${id}`)
        if (cookie) {
            try {
                const parsedData = JSON.parse(cookie)
                console.log(parsedData)
                setSaveData(parsedData)
                setQuizState(parsedData.quizState)
                setQuizPoints(parsedData.quizPoints)
            } catch {
                console.log("couldn't get saved data.")
            }
        } else {
            const newData = new TopicData()
            setSaveData(newData)
        }

        const data = Cookies.get(`history`)
        if (data) {
            try {
                const parsedData = JSON.parse(data)
                setHistoryPerDate([
                    parsedData.topicsViewed.filter(t => {
                        const now = Date.now();
                        const oneWeek = 7 * 24 * 60 * 60 * 1000;
                        const diff = now - t.date;
                        return diff < oneWeek
                    }),
                    parsedData.topicsViewed.filter(t => {
                        const now = Date.now();
                        const oneWeek = 7 * 24 * 60 * 60 * 1000;
                        const oneMonth = 30 * 24 * 60 * 60 * 1000;
                        const diff = now - t.date;
                        return diff < oneMonth && diff > oneWeek
                    }),
                    parsedData.topicsViewed.filter(t => {
                        const now = Date.now();
                        const oneMonth = 30 * 24 * 60 * 60 * 1000;
                        const diff = now - t.date;
                        return diff > oneMonth
                    }),
                ])
            } catch {
                console.log("couldn't get history data.")
            }
        } else {
            setHistoryPerDate([[], [], []])
        }
    }, [])

    function saveCookies() {
        Cookies.set(id, JSON.stringify(saveData));
    }


    function turnCard(number) {
        const saveDataClone = saveData
        saveDataClone.cardsViewed[number].wasViewed = true
        saveDataClone.cardsViewed[number].date = Date.now()
        setSaveData(saveDataClone)
        saveCookies()
        setCurrentCardStatus(!currentCardStatus)
    }

    function quizGuess(number) {
        if (number === topic.quiz.questions[currentQuestion].answer) {
            setQuizState("right")
            setQuizPoints(quizPoints + 1)
        } else {
            switch (number) {
                case 1:
                    setQuizExplanation(topic.quiz.questions[currentQuestion].opt1exp)
                    break
                case 2:
                    setQuizExplanation(topic.quiz.questions[currentQuestion].opt2exp)
                    break
                case 3:
                    setQuizExplanation(topic.quiz.questions[currentQuestion].opt3exp)
                    break
                case 4:
                    setQuizExplanation(topic.quiz.questions[currentQuestion].opt4exp)
                    break
                default:
                    console.log("no!")
                    break
            }
            setQuizState("wrong")
        }
    }

    function quizNextQuestion() {
        setQuizExplanation("")
        if (currentQuestion < 4) {
            setQuizState("question")
            setCurrentQuestion(currentQuestion + 1)
        } else {
            switch (quizPoints) {
                case 1:
                    setQuizReview(["Quaaaaase lá!", "Com um pouco mais de prática, você vai voar alto!"])
                    break
                case 2:
                    setQuizReview(["Tá no caminho!", "Mais um pouco de dedicação e você chega lá!"])
                    break
                case 3:
                    setQuizReview(["É, tá massa", "Foi bem, mas sei que você pode ir ainda mais longe!"])
                    break
                case 4:
                    setQuizReview(["Jogou fácil!", "Só falta um pouquinho pra perfeição!"])
                    break
                case 5:
                    setQuizReview(["Que isso po, aulas!", "Você tá dominando o assunto, parabéns!"])
                    break
                default:
                    setQuizReview(["Relaxa, foi só um tropeço!", "Vamos dar uma revisada e tentar de novo?"])
                    break
            }
            const saveDataClone = saveData
            saveDataClone.quizState = "end"
            saveDataClone.quizPoints = quizPoints
            saveDataClone.quizDate = Date.now()
            setSaveData(saveDataClone)
            setQuizState("end")
            saveCookies()
        }
    }

    function restartQuiz() {
        setQuizPoints(0)
        setCurrentQuestion(0)
        setQuizExplanation("")
        setQuizState("question")
    }

    function changeCard(up) {
        if (up) {
            if (currentCard < 4) {
                setCurrentCard(currentCard + 1)
            } else {
                setCurrentCard(0)
            }
        } else {
            if (currentCard > 0) {
                setCurrentCard(currentCard - 1)
            } else {
                setCurrentCard(4)
            }
        }
        setCurrentCardStatus(false)
    }

    function dateFormat(date) {
        return format(date, 'dd/MM/yy')
    }

    return (
        <div className="topicview">
            <nav>
                <div className="icon">
                    <a href="/"><img src="./logobranco.png" alt="logo" /></a>
                    <div className="icon-title">{topic.name}</div>
                </div>
                <button className='menu-button' onClick={() => setHistoryBarOpened(true)}><img src='./menu.png' alt='menu' /></button>
            </nav>

            <div
                className={`historybar ${historyBarOpened ? 'open' : ''}`}
                onMouseLeave={() => setHistoryBarOpened(false)}
            >
                <div className='historybar-titulo'>Histórico</div>
                <div className='historybar-separador'></div>

                {
                    historyPerDate[0].length === 0 && historyPerDate[1].length === 0 && historyPerDate[2].length === 0 ?
                    <div className='historybar-filler'>
                        Ainda não tem nada aqui
                    </div> :
                    <></>
            }

                {historyPerDate[0].length > 0 ?
                    <div className='historybar-section'>
                        <div className='historybar-section-title'>Essa semana</div>
                        {historyPerDate[0].map((item, index) => (
                            <a className='historybar-link' key={item.id} href={`${item.id}`}>{item.name}</a>
                        ))}
                    </div>
                    : null}

                {historyPerDate[1].length > 0 ?
                    <div className='historybar-section'>
                        <div className='historybar-section-title'>Esse mês</div>
                        {historyPerDate[1].map((item, index) => (
                            <a className='historybar-link' key={item.id} href={`${item.id}`}>{item.name}</a>
                        ))}
                    </div>
                    : null}

                {historyPerDate[2].length > 0 ?
                    <div className='historybar-section'>
                        <div className='historybar-section-title'>Antigo</div>
                        {historyPerDate[2].map((item, index) => (
                            <a className='historybar-link' key={item.id} href={`${item.id}`}>{item.name}</a>
                        ))}
                    </div>
                    : null}

                <div style={{ margin: "30px" }}></div>
            </div>


            <div className="resumo">
                <div className="resumo-tag">
                    <div className="resumo-titulo">Relembre o Conteúdo</div>
                    <div className="resumo-subtitulo">Revisar nunca é demais</div>
                </div>
                <div className="resumo-texto" dangerouslySetInnerHTML={{ __html: topic.resumo }}></div>
            </div>

            <div className="cards">
                <div className="cards-tag">
                    <div className="cards-titulo">Revise os tópicos mais importantes</div>
                    <div className="cards-subtitulo">Fique cada vez mais preparado(a)</div>
                </div>
                <div className="cards-fundo">
                    <div className="cards-top">
                        {saveData && saveData.cardsViewed[currentCard].wasViewed === true ?
                            <>
                                <div className="cards-top-text" style={cardDataHover ? { opacity: "100%" } : { opacity: "0%" }}>Visto em {dateFormat(saveData.cardsViewed[currentCard].date)}</div>
                                <div className="cards-top-icon" onMouseEnter={() => setCardDataHover(true)} onMouseLeave={() => setCardDataHover(false)}><img alt="card já visto" src="./cardvisto.svg" /></div>
                            </>
                            : null
                        }
                    </div>
                    <div className="cards-center">
                        <div className="cards-arrows" onClick={() => changeCard(false)}>&lt;</div>
                        <div className={currentCardStatus ? "card turned" : "card"} onClick={() => turnCard(currentCard)}>
                            {topic.cards && topic.cards.length > 0 ? (
                                currentCardStatus ? topic.cards[currentCard].definicao : topic.cards[currentCard].palavra
                            ) : (
                                "Carregando..."
                            )}
                        </div>
                        <div className="cards-arrows" onClick={() => changeCard(true)}>&gt;</div>
                    </div>
                    <div className="cards-bottom">
                        <span className={currentCard === 0 ? "cards-bottom-selected" : "cards-bottom-regular"} onClick={() => setCurrentCard(0)}>●</span>
                        <span className={currentCard === 1 ? "cards-bottom-selected" : "cards-bottom-regular"} onClick={() => setCurrentCard(1)}>●</span>
                        <span className={currentCard === 2 ? "cards-bottom-selected" : "cards-bottom-regular"} onClick={() => setCurrentCard(2)}>●</span>
                        <span className={currentCard === 3 ? "cards-bottom-selected" : "cards-bottom-regular"} onClick={() => setCurrentCard(3)}>●</span>
                        <span className={currentCard === 4 ? "cards-bottom-selected" : "cards-bottom-regular"} onClick={() => setCurrentCard(4)}>●</span>
                    </div>
                </div>
            </div>

            <div className="quiz">
                <div className="quiz-tag">
                    <div className="quiz-titulo">Teste seus conhecimentos</div>
                    <div className="quiz-subtitulo">A prática leva a perfeição</div>
                </div>
                <div className="quiz-fundo">
                    {quizState === "end" ? <div className="quiz-end">
                        <div className="quiz-score">{quizPoints}/5</div>
                        <div className="quiz-review-title">{quizReview[0]}</div>
                        <div className="quiz-review-text">{quizReview[1]}</div>
                        <div className="quiz-option" onClick={restartQuiz}>Tente de Novo</div>
                        <div className="quiz-review-date">última tentativa em {dateFormat(saveData.quizDate)}</div>
                    </div> :
                        <>
                            <div className="quiz-question">
                                {topic.quiz && topic.quiz.questions.length > 0 ? (
                                    topic.quiz.questions[currentQuestion].question
                                ) : (
                                    "Carregando..."
                                )}
                            </div>
                            <div className="quiz-options">
                                <div className="quiz-option" onClick={() => quizGuess(1)}>
                                    {topic.quiz && topic.quiz.questions.length > 0 ? (
                                        topic.quiz.questions[currentQuestion].opt1
                                    ) : (
                                        "Carregando..."
                                    )}
                                </div>
                                <div className="quiz-option" onClick={() => quizGuess(2)}>
                                    {topic.quiz && topic.quiz.questions.length > 0 ? (
                                        topic.quiz.questions[currentQuestion].opt2
                                    ) : (
                                        "Carregando..."
                                    )}
                                </div>
                                <div className="quiz-option" onClick={() => quizGuess(3)}>
                                    {topic.quiz && topic.quiz.questions.length > 0 ? (
                                        topic.quiz.questions[currentQuestion].opt3
                                    ) : (
                                        "Carregando..."
                                    )}
                                </div>
                                <div className="quiz-option" onClick={() => quizGuess(4)}>
                                    {topic.quiz && topic.quiz.questions.length > 0 ? (
                                        topic.quiz.questions[currentQuestion].opt4
                                    ) : (
                                        "Carregando..."
                                    )}
                                </div>
                            </div>

                            {quizState !== "right" && quizState !== "wrong" ? <></>
                                :
                                <div className={quizState === "right" ? "quiz-explanation quiz-right" : "quiz-explanation quiz-wrong"} onClick={quizNextQuestion}>
                                    <img src={quizState === "right" ? "./right.png" : "./wrong.png"} alt="revisai logo" />
                                    {quizExplanation !== "" ? <div className="quiz-explanation-text">
                                        {quizExplanation}
                                    </div> :
                                        <></>}
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div >
    )
}