import "./404.css"
export default function Erro() {
    return (
        <div className="error-page">
            <img src="./wrong.png" alt="404 not found" />
            <div className="error-title">404 not found :/</div>
            <div className="error-text">Foi mal, algo deu errado com nossos robôzinhos. Vê se o nome do assunto que você botou faz sentido mesmo e tenta de novo!</div>
            <a className="error-voltar" href="/">voltar pra home</a>
        </div>
    )
}