class Question {
    constructor(question, opt1, opt1exp, opt2, opt2exp, opt3, opt3exp, opt4, opt4exp, answer) {
        this.question = question // string
        this.opt1 = opt1 // string 
        this.opt1exp = opt1exp // string (explicacao resposta errada ou "" se for a certa) 
        this.opt2 = opt2 // string
        this.opt2exp = opt2exp // string (explicacao resposta errada ou "" se for a certa)
        this.opt3 = opt3 // string 
        this.opt3exp = opt3exp // string (explicacao resposta errada ou "" se for a certa)
        this.opt4 = opt4 // string
        this.opt4exp = opt4exp // string (explicacao resposta errada ou "" se for a certa)
        this.answer = answer // int, 1 2 3 ou 4
    }
}

class Quiz {
    constructor(questions) {
        this.questions = questions // Question (5 questions)
    }
}

class Card {
    constructor(palavra, definicao) {
        this.palavra = palavra // string
        this.definicao = definicao // string
    }
}
class Topic {
    constructor(id, language, nome, resumo, cards, quiz) {
        this.id = id // string
        this.language = language // "pt" or "en"
        this.name = nome // string
        this.resumo = resumo // string (dois paragrafos de explicacao)
        this.cards = cards // Card (5 cards)
        this.quiz = quiz // Quiz (1 quiz)
    }
}

export {Question, Quiz, Card, Topic}