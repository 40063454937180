export class CardVisualization {
    constructor() {
        this.wasViewed = false
        this.date = null
    }
}

export class TopicData {
    constructor() {
        this.cardsViewed = [new CardVisualization(), new CardVisualization(), new CardVisualization(), new CardVisualization(), new CardVisualization()]
        this.quizState = "question" // question ou end
        this.quizPoints = 0 // 1
        this.quizDate = null //11 9
    }
}

export class SearchTopic {
    constructor(id, name, date) {
        this.id = id
        this.name = name
        this.date = date
    }
}

export class History {
    constructor() {
        this.topicsViewed = [] // [SearchTopic]
    }
}